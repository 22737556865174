import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import img from '../logo-1.png';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const theme = createTheme({
  palette: {
    blackcolor: {
      main: '#000000',
      light: '#000000',
      dark: '#A29415',
      contrastText: '#242105',
    },
     poscolor: {
      main: '#9f2f3c',
      light: '#000000',
      dark: '#A29415',
      contrastText: '#242105',
    },primary: {
      main: '#9f2f3c',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
});

const pages = [ {link:'/',text:'Home',subMenu: []}, {link:'/reservierung',text:'Reservierung',subMenu:[]},
// {link:'/',
//   text:"SPEISEKARTE",subMenu: [
//   {
//     key: "FRÜHSTÜCK",link:'/frühstückskarte'
//   },
//   {
//     key: "ALACARTE ",link:'/alacarte'
//   }
// ]}, 
{link:'/uberuns',text:'Über uns',subMenu:[]}
,{link:'/contact',text:'Kontakt',subMenu:[]}];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [parentWidth, setParentWidth] = React.useState(0);
  const [parentHt, setParentHt] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <ThemeProvider theme={theme}>
    <AppBar  position="static" color="blackcolor"  >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          
         
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="poscolor"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              style={{backgroundColor:'transparent'}}
            >
              {pages.map((page) => (
                // <Link to={page.link} style={{ textDecoration: 'none' }}>
                // <MenuItem key={page.text}  onClick={handleCloseNavMenu} style={{background:'#BAB86C',color:'#9f2f3c', margin:'1px'}}>
                //   <Typography textAlign="center" >{page.text}</Typography>
                // </MenuItem></Link>
                <MenuItemWithSubMenu data={page} key={page.text} handleCloseNavMenu={handleCloseNavMenu}></MenuItemWithSubMenu>
              ))}
            </Menu>
            <img src={img}  style={{width:'60%',margin:'auto'}}/>
          </Box>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           
            {pages.map((page) => (
             
                <MenuItemWithSubMenu data={page} key={page.text} handleCloseNavMenu={handleCloseNavMenu}></MenuItemWithSubMenu>
            
            
              
            ))}
             <img src={img}  style={{width:'50%',margin:'auto'}}/>
          </Box>

         
        </Toolbar>
      </Container>
    </AppBar>
    </ThemeProvider>
  );
}
export default ResponsiveAppBar;

const MenuItemWithSubMenu = ({ data,handleCloseNavMenu }) => {
  const { text, subMenu,link } = data || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuRef = React.useRef(null);
  const [parentWidth, setParentWidth] = React.useState(0);
  const [parentHt, setParentHt] = React.useState(0);

  React.useEffect(() => {
    const { offsetWidth, offsetHeight } = menuRef?.current || {};
    setParentWidth(offsetWidth);
    setParentHt(offsetHeight);
  }, [menuRef]);

  return (
    <>
      <MenuItem
        ref={menuRef}
        id={text}
        onClick={(e) => setAnchorEl(e.currentTarget) }
        style={{background:'transparent',color:'#9f2f3c', margin:'1px'}}
      >
       {subMenu.length == 0 && <Link to={link} style={{ textDecoration: 'none' }}><Button
                key={text}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
                style={{color:'#9f2f3c'}}
              > {text}</Button></Link>} {subMenu.length > 0 && <><Button  key={text}   sx={{ my: 2, color: 'white', display: 'block' }}
                style={{color:'#9f2f3c'}}
              >{text}</Button><ArrowRightIcon /></>}
      </MenuItem>
      {subMenu.length > 0 && (
        <Menu
          id="basic-sub-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": text
          }}
          style={{
            left: parentWidth * 0.9,
            top: -(parentHt * 0.53)
          }}
        >
          {subMenu.map((subMenuItem, i) => (
            <Link to={subMenuItem.link} style={{ textDecoration: 'none' }}><MenuItem key={i} onClick={() => {setAnchorEl(null);handleCloseNavMenu()}} style={{background:'transparent',color:'#9f2f3c', margin:'1px'}}>
              {subMenuItem.key}
            </MenuItem></Link>
          ))}
        </Menu>
      )}
    </>
  );
};

